import { firebaseClient, getToken } from "../firebaseClient";
import * as analytics from "../utils/analytics";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { getAccount, updateUser, deleteCredentialsIfExpired } from "../utils/sessionHelper";
import { setInLocalStorage } from "../utils/helpers";
import queryString from "query-string"
import { checkCredit, isAllowed, useCredit } from "./subscription";
import { CustomToast } from "components/toasts/CustomToast";

export const formatGenerateResponse = async (data, session) => {
  let tweets: any = [];
  let array = data.tweetsRich ? data.tweetsRich : data.tweets.map(tw => { return { text: tw } });
  array.forEach((tw) => {
    if (tw != "empty") {
      let fullTweet = {
        ...tw,
        full_text: tw.text,
        user: {
          profile_image_url_https: getAccount(session).image,
          name: getAccount(session).name,
          screen_name: getAccount(session).username,
          customProfileLink: "https://www.linkedin.com/in/" + getAccount(session).username,
        },
      };
      tweets.push(fullTweet);
    }
  });
  data.originals.forEach((tw) => {
    if (tw?.created_at?._seconds)
      tw.created_at = new Date(tw.created_at._seconds * 1000);
  });
  data.tweets = tweets;
}

export const search = async (session, topic, params = undefined as any, mode = "search", mainContext = undefined as any, conf = {} as any) => {

  console.log("search " + topic);

  let userData = getAccount(session);
  // console.log({userData});

  if (!topic) {
    toast.error("Please enter a topic", { style: { background: "gray.600", color: "#222" } });
    return;
  }

  if (mode != "server")
    setInLocalStorage('searchTopic_' + mode, topic);

  if ((!userData?.subscription?.isSubscribed || (conf.feature && !isAllowed(session.user, conf.feature)))
    && userData?.remainingFreeSearch
    && userData.remainingFreeSearch > 0
  ) {
    consumeFreeSearch(session);
  }
  else {
    if (!userData)
      console.log("Error: session not initialized");
    else if (!userData?.subscription?.isSubscribed) {
      mainContext && mainContext.onOpenUpgrade();
      return;
    }
  }

  try {

    if (params) {
      console.log("isInfluentLeader");
      params.isInfluentLeader = true;
      if (getAccount(session)?.isAdmin)
        params.isAdmin = true;
    }

    let properties = { mode: "", topic: "" } as any;
    if (params) {
      properties = {
        //@ts-ignore
        ...params,
      }
    }
    properties.mode = mode;
    properties.topic = topic;
    analytics.log("search_performed", properties);
    let randomize = true;

    if ((!userData?.subscription?.isSubscribed && !userData?.remainingFreeSearch)
      || (userData?.disableRandom && window?.location?.href?.includes("dev"))
    ) {
      randomize = false;
    }

    let call = "tweets";
    if (mode == "generate") call = "searchAndGenerate";
    else if (mode == "searchAndGenerateVariations") call = "searchAndGenerateVariations";
    else if (mode == "generateFromListTaplio") call = "generateFromListTaplio";
    else if (mode == "generateFromList") call = "generateFromList";

    let url = `https://api.tweetbutler.com/${call}?topic=${encodeURIComponent(topic)}&randomize=${randomize}&description=${encodeURIComponent(userData?.description)}`;

    if (["generateFromListTaplio", "generateFromList"].includes(mode)) url += `&userId=${session?.user?.uid}`;
    else url += `&userId=${userData.idAccount}`;

    // url += "&disableLinkedin=false";
    // url += "&disableTwitter=false";
    // let i=3; i="3";

    if (params) {
      addBasePropertiesToParams(params, session);
      url += "&" + new URLSearchParams(params).toString();
    }

    if (!url.includes("disableLinkedin")) url += "&disableLinkedin=false";
    if (!url.includes("disableTwitter")) url += "&disableTwitter=false";

    if (!url.includes("count"))
      url += "&count=40";

    //   console.log("url: " + url);
    const token = await getToken(session, "search-" + call);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        tokenuserid: session.user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    // let response = await fetch(url);

    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error, { style: { background: "gray.600", color: "#222" } });
    }

    return data;
  }
  catch (e) {
    console.log("Error in fetch getTweets: " + e.message, JSON.stringify({ e, params }));
    toast.error("An error occurred, please try again", { style: { background: "gray.600", color: "#222" } });
    Sentry.captureException(e);
  }
};

export const searchFromServer = async (topic, params = undefined) => {

  try {
    let randomize = true;

    let call = "tweets";

    let url = `https://api.tweetbutler.com/${call}?topic=${encodeURIComponent(topic)}&count=40&randomize=${randomize}&isInfluentLeader=true`;

    if (params) {
      url += "&" + new URLSearchParams(params).toString();
      url += "&key=" + process.env.TWEETBUTLER_KEY;
    }

    console.log("url: " + url);
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'X-CALL-ORIGIN': "server"
      }
    });
    let data = await response.json();

    return data;
  }
  catch (e) {
    console.log("Error in searchFromServer: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const generatePost = async (tweet, session) => {

  console.log("generatePost for: " + tweet.full_text);

  if (!tweet) {
    toast.error("Please enter a valid tweet", { style: { background: "gray.600", color: "#222" } });
    return;
  }

  try {

    let properties = { tweet: tweet };
    

    let url = `https://api.tweetbutler.com/generatePost?text=${encodeURIComponent(tweet.full_text)}&isInfluentLeader=true`;
    const token = await getToken(session, "generatePost");
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        tokenuserid: session.user.uid,
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error, { style: { background: "gray.600", color: "#222" } });
    }

    return data;
  }
  catch (e) {
    console.log("Error in generatePost: " + e.message);
    toast.error("An error occurred, please try again", { style: { background: "gray.600", color: "#222" } });
    Sentry.captureException(e);
  }
}

export const grow = async (session, params = {} as any, onOpenUpgrade = undefined) => {

  try {

    let userData = getAccount(session);

    if (!userData.idParent) {
      toast.error("Please connect your LinkedIn account to use this feature");
      return;
    }

    if (!isAllowed(session.user, "engage")) {
      if (userData?.remainingFreeSearch && userData.remainingFreeSearch > 0) {
        consumeFreeSearch(session);
      }
      else {
        console.log("not allowed");
        //@ts-ignore
        onOpenUpgrade && onOpenUpgrade();
        return;
      }
    }

    params.userId = userData.idParent;
    params.idAccount = userData.idAccount;
    params.description = encodeURIComponent(userData?.description);
    params.keywords = encodeURIComponent(userData?.keywords?.join(","));

    
    let url = `https://us-central1-ez4cast.cloudfunctions.net/linkedinGrow-grow`;

    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }

    // console.log("url: " + url);

    let response = await fetch(url);
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
      deleteCredentialsIfExpired(session, data.code);
    }

    return data;
  }
  catch (e) {
    console.log("Error in fetch grow: " + e.message);
    toast.error("An error occurred, please try again", { style: { background: "gray.600", color: "#222" } });
    Sentry.captureException(e);
  }
};

export const getHooks = async (session, params, mainContext) => {

  setInLocalStorage('searchTopic_hooks', params.topic);

  try {
    // if (checkCredit( session, mainContext, 'creditsAi' ,true ) === false) return

    params.useBestAi = await useCredit(session, mainContext, "creditsBestai", false);

    
    let url = `https://api.tweetbutler.com/hooks`;

    if (params)
      url += "?" + new URLSearchParams(params).toString();

    // start toast custom
    const toastIdCustom = toast.loading(<CustomToast useCreditBoolean={params.useBestAi} />);

    const token = await getToken(session, "getHooks");

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
    });
    let data = await response.json();

    // end toast custom
    toast.dismiss(toastIdCustom);

    if (data.success === 0) {
      toast.error(data.error);
    }

    // useCredit(session, mainContext)

    return data;
  }
  catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};

export const getIdeas = async (session, params, onOpenUpgrade = undefined) => {

  setInLocalStorage('searchTopic_ideas', params.topic);

  try {
    let userData = getAccount(session);

    if (!isAllowed(session.user, "idea")) {
      if (userData?.remainingFreeSearch && userData.remainingFreeSearch > 0) {
        consumeFreeSearch(session);
      }
      else {
        console.log("not allowed");
        //@ts-ignore
        onOpenUpgrade && onOpenUpgrade();
        return;
      }
    }

    
    let url = `https://api.tweetbutler.com/generateIdeas`;

    if (params)
      url += "?" + new URLSearchParams(params).toString();

    const token = await getToken(session, "getIdeas");
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
    });
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
    }

    return data;
  }
  catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};

export const continuePost = async (session, params, mainContext = null) => {

  try {

    // if (! await useCredit(session, mainContext))
    //   return;

    params.useBestAi = await useCredit(session, mainContext, "creditsBestai", false);


    
    let url = `https://api.tweetbutler.com/generateNext`;
    // start toast custom
    const toastIdCustom = toast.loading(<CustomToast useCreditBoolean={params.useBestAi} />);
    const token = await getToken(session, "continuePost");
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
      body: JSON.stringify({
        ...params,
        isInfluentLeader: true,
      }),
    });
    const data = await response.json();

    // end toast custom
    toast.dismiss(toastIdCustom);


    if (data.success === 0) {
      toast.error(data.error);
    }

    return data;
  }
  catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};


export const warm = async (session) => {

  try {
    let user = session.user;
    let idAccount = getAccount(session)?.idAccount;
    let url = `https://api.tweetbutler.com/warm?id=${idAccount}&isInfluentLeader=true&username=${getAccount(session)?.username}`;
    const token = await getToken(session, "warm");
    let res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await res.json();

    // console.log(data);
    // console.log("user.uid: " + user.uid);
    let dataToSave: any = {};

    if (data.description && user.uid) {

      if (data.description) {
        dataToSave.description = data.description;
        user.data.description = data.description;
      }
      if (data.who) {
        dataToSave.who = data.who;
        user.data.who = data.who;
      }
      if (data.topics) {
        dataToSave.topics = data.topics;
        user.data.topics = data.topics;
      }
    }
    else if (data.bio && user.uid) {
      //@ts-ignore
      dataToSave.description = data.bio;
      //@ts-ignore
      dataToSave.who = data.bio;
      //@ts-ignore
      dataToSave.topics = "";
    }
    else {
      // //@ts-ignore
      // dataToSave.description = "none";
      // //@ts-ignore
      // dataToSave.who = "none";
      // //@ts-ignore
      // dataToSave.topics = "none";
    }

    // const db = firebaseClient.firestore();
    // await db.collection("users").doc(user.uid).update({ ...dataToSave });

    await updateUser(session, dataToSave);

    return data;
  }
  catch (e) {
    console.log("Error in warm: " + e.message, e);
    Sentry.captureException(e);
    return null;
  }
};

export const getAggreeDisaggree = async (isAggree, text, news, session, mainContext) => {

  const useCreditBoolean = await useCredit(session, mainContext, "creditsBestai", false)

  try {
    let url = `https://us-central1-ez4cast.cloudfunctions.net/news-aggreeDisaggree?isAggree=${isAggree}&text=${encodeURIComponent(text)}&newsDesc=${encodeURIComponent(news.description)}&newsTitle=${encodeURIComponent(news.title)}&newsUrl=${encodeURIComponent(news.url)}`;
    url += "&useBestAi=" + useCreditBoolean;
    url += "&description=" + encodeURIComponent(getAccount(session)?.description);
    url += "&tokenUserId=" + session.user.uid;
    url += "&token=" + await getToken(session, "getAggreeDisaggree");

    // start toast custom
    const toastIdCustom = toast.loading(<CustomToast useCreditBoolean={useCreditBoolean} />);
    let response = await fetch(url);
    let data = await response.json();
    // end toast custom 
    toast.dismiss(toastIdCustom);

    if (data.success === 1 && data?.generations?.length > 0) {
      return data?.generations[0];
    }
    else {
      toast.error("Failed to generation opinion");
    }

    return null;
  }
  catch (e) {
    console.log("Error in getAggreeDisaggree: " + e.message);
    Sentry.captureException(e);
  }
}

export const getKeywords = async (session) => {

  try {
    console.log("getKeywords");
    let user = session?.user;

    let url;
    if (user?.data?.description)
      url = `https://api.tweetbutler.com/keywords?text=${encodeURIComponent(getAccount(session).description)}&isInfluentLeader=true`;
    else
      url = `https://api.tweetbutler.com/keywords?id=${getAccount(session).idAccount}&isInfluentLeader=true`;
    const token = await getToken(session, "getKeywords");
    let res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await res.json();

    if (data.success) {
      console.log("Got keywords: " + data.keywords);
      let dataToSave = {
        keywords: data.keywords,
      };
      // const db = firebaseClient.firestore();
      // await db.collection("users").doc(user.uid).update({ ...dataToSave });
      // postData({
      //   url: "user-updateDataTweetButlerDb",
      //   token: "",
      //   data: {
      //     idUser: user.uid,
      //     data: dataToSave,
      //     user: user,
      //   },
      // }).catch((e) => {
      //   console.error("Error: " + e.message);
      // });

      await updateUser(session, dataToSave, false, true);

      return data.keywords;
    }

    return null;
  }
  catch (e) {
    console.log("Error in warm: " + e.message, e);
    Sentry.captureException(e);
    return null;
  }
};

export const getHandleSuggestions = async (session, params, signal?: any) => {
  try {
    // let url = `https://api.tweetbutler.com/autocomplete?`;
    let url = `https://us-central1-ez4cast.cloudfunctions.net/linkedinAutoComplete-autoComplete?`;

    if (params) {
      addBasePropertiesToParams(params, session);
      url += "&" + new URLSearchParams(params).toString();
    }

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal
    });

    let data = await response.json();

    // if (data.success === 0) {
    //   toast.error(data.error, {style: {background: "gray.600",color: "#222"}});
    // }

    return data;
  } catch (e) {
    console.log("Error in fetching username suggestions: ", e.message);
    Sentry.captureException(e);
    return null;
  }
};

export const getTweet = async (session, tweetId: string, signal?: any) => {
  try {
    const payload: any = {
      twAccessToken: getAccount(session)?.thWriteAccessToken,
      twSecretToken: getAccount(session)?.thWriteSecretToken,
      app: getAccount(session)?.thApp,
      idTweet: tweetId,
      isInfluentLeader: true,
    };

    const url = queryString.stringifyUrl({
      url: "https://us-central1-ez4cast.cloudfunctions.net/twitterFetcher-getTweet",
      query: payload
    })

    const token = await getToken(session, "getTweet");

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: session?.user?.uid,
        Authorization: `Bearer ${token}`,
      },
      signal
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("Error in fetching tweet: ", err);
    Sentry.captureException(err);
    return null;
  }
}

async function consumeFreeSearch(session) {
  session.user.data.remainingFreeSearch--;
  const db = firebaseClient.firestore();
  await db.collection("users")
    .doc(session.user.uid)
    .update({ remainingFreeSearch: firebaseClient.firestore.FieldValue.increment(-1) }).catch((e) => {
      console.log("e: " + e.message);
    });
}

function addBasePropertiesToParams(params, session) {
  if (params) {
    if (getAccount(session)?.thWriteAccessToken) {
      params.twAccessToken = getAccount(session)?.thWriteAccessToken;
      params.twSecretToken = getAccount(session)?.thWriteSecretToken;
      params.thApp = getAccount(session)?.thApp ?? "";
      params.app = getAccount(session)?.thApp ?? "";
      params.tokenType = "write";
    }
    else if (getAccount(session)?.thReadAccessToken) {
      params.twAccessToken = getAccount(session)?.thReadAccessToken;
      params.twSecretToken = getAccount(session)?.thReadSecretToken;
      params.tokenType = "read";
    }
  }
}